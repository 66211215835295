export const flattenMessages = (messages = {}, prefix = '') =>
  Object.keys(messages).reduce((obj, key) => {
    const value = messages[key]
    const prefixedKey = prefix ? `${prefix}.${key}` : key

    if (typeof value === 'string') {
      return {
        ...obj,
        [prefixedKey]: value
      }
    }

    return {
      ...obj,
      ...flattenMessages(value, prefixedKey)
    }
  }, {})
