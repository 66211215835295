import antd from 'antd/lib/locale-provider/zh_CN'
import localeData from 'react-intl/locale-data/zh'

import messages from './json/zh.yml'
import { flattenMessages } from './utils'

export default {
  messages: flattenMessages(messages),
  antd,
  data: localeData
}
