/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react'
import './src/styles/common.less'

import Layout from './src/components/Layout'
import { pushLinkToBaidu } from './src/utils'

// Pass all props (hence the ...props) to the layout component so it has access to things like pageContext or location
export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
)

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  pushLinkToBaidu()
}
