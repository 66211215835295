import React from 'react'
import { Layout, Row, Col, Popover, Button, Icon } from 'antd'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

import weChatImg from './wechat.jpg'
import styles from './index.module.less'

const socialList = (
  <ul>
    <li>
      <Button shape="circle" icon="mail" />
      support@kankanai.com.cn
    </li>
    <li>
      <Popover
        overlayClassName={styles.socialImage}
        content={<img src={weChatImg} alt="" />}
        trigger="hover"
      >
        <Button shape="circle" icon="wechat" />
        KanKanAI
      </Popover>
    </li>
  </ul>
)

const Footer = props => {
  return (
    <Layout.Footer className={styles.footer}>
      <div className="container">
        <Row>
          <Col xs={24} md={{ span: 16 }} className={styles.info}>
            <div className={`${styles.social} hidden-desktop`}>
              {socialList}
            </div>
            <div className={styles.tel}>
              <Icon type="phone" className="hidden-mobile" />
              <div>
                <p>
                  <FormattedMessage id="footer.tel" />
                </p>
                <p className={styles.telNum}>
                  <a href="tel:028-88722799">86-28-85138533</a>
                </p>
              </div>
            </div>
            <p className={styles.qq}>
              <span>
                <FormattedMessage id="footer.workdays" />
              </span>
            </p>
            <p className={styles.addr}>
              <span>
                <FormattedMessage id="footer.addr" />
              </span>
              <span>
                <FormattedMessage id="footer.zip" />
              </span>
            </p>
            <p className={styles.addr}>
              <span>
                <FormattedMessage id="footer.addr_hz" />
              </span>
              <span>
                <FormattedMessage id="footer.zip_hz" />
              </span>
            </p>
            <div className={`${styles.social} hidden-mobile`}>{socialList}</div>
            {/* <div className={`${styles.friends} hidden-desktop`}>
            {friendsLinks}
          </div> */}
            <p className={styles.copy}>
              <FormattedHTMLMessage id="footer.copyright" />
            </p>
          </Col>
          <Col
            className={styles.col}
            style={{ height: '120px' }}
            xs={24}
            md={{ span: 1 }}
          >
            <div className={styles.line}></div>
          </Col>
          <Col xs={24} md={{ span: 7 }}>
            <p className={styles.qq}>
              <span>
                <FormattedMessage id="footer.aK" />
              </span>
            </p>
            <p style={{ fontSize: '18px' }} className={styles.com}>
              <span>
                <a
                  href="http://www.shufengai.com/"
                  style={{ color: 'white' }}
                  target="_blank"
                >
                  <a
                    className={styles.point}
                    style={{
                      fontSize: '40px',
                      marginRight: '5px',
                      verticalAlign: 'sub',
                      color: 'white'
                    }}
                  >
                    ·
                  </a>
                  <FormattedMessage id="footer.com" />
                </a>
              </span>
            </p>
          </Col>
        </Row>
      </div>
    </Layout.Footer>
  )
}

export default Footer
