// import Cookies from 'js-cookie'

// import { LANGUAGES } from 'constants/site'

export const isBrowser = typeof window !== 'undefined'

export const isProdUrl =
  isBrowser && window.location.hostname.indexOf('kankanai.com.cn') > -1

export const toQueryString = paramsObj =>
  Object.keys(paramsObj)
    .filter(key => !!paramsObj[key])
    .map(
      key => `${encodeURIComponent(key)}=${encodeURIComponent(paramsObj[key])}`
    )
    .join('&')

const addZero = num => (num < 10 ? `0${num}` : num)

export const formatDate = d => {
  const date = new Date(d)
  const year = date.getFullYear()
  const month = addZero(date.getMonth() + 1)
  const day = addZero(date.getDate())
  const hour = addZero(date.getHours())
  const min = addZero(date.getMinutes())

  return {
    date: `${year}-${month}-${day}`,
    time: `${hour}:${min}`
  }
}

export const pushLinkToBaidu = () => {
  if (isProdUrl) {
    const curProtocol = window.location.protocol.split(':')[0]

    if (curProtocol === 'https') {
      // https://zz.bdstatic.com/linksubmit/push.js
      !(function() {
        var e = /([http|https]:\/\/[a-zA-Z0-9\_\.]+\.baidu\.com)/gi
        var r = window.location.href
        var t = document.referrer
        if (!e.test(r)) {
          var o = 'https://sp0.baidu.com/9_Q4simg2RQJ8t7jm9iCKT-xh_/s.gif'
          t
            ? ((o += '?r=' + encodeURIComponent(document.referrer)),
              r && (o += '&l=' + r))
            : r && (o += '?l=' + r)
          var i = new Image()
          i.src = o
        }
      })(window)
    } else {
      // http://push.zhanzhang.baidu.com/push.js
      !(function() {
        var e = /([http|https]:\/\/[a-zA-Z0-9\_\.]+\.baidu\.com)/gi
        var r = window.location.href
        var o = document.referrer
        if (!e.test(r)) {
          var n = '//api.share.baidu.com/s.gif'
          o
            ? ((n += '?r=' + encodeURIComponent(document.referrer)),
              r && (n += '&l=' + r))
            : r && (n += '?l=' + r)
          var t = new Image()
          t.src = n
        }
      })(window)
    }
  }
}

export const addBaiduTongJiScript = () => {
  if (isProdUrl) {
    const hm = document.createElement('script')
    hm.src = 'https://hm.baidu.com/hm.js?b9ac2f1dc50b10dccd07dedb8820e2aa'
    const s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(hm, s)
  }
}

export const pushTrackToBaidu = () => {
  const hmt = window._hmt

  if (isProdUrl && hmt && hmt.push) {
    const url = window.location.href

    hmt.push(['_trackPageview', url])
  }
}

export const getDefaultLang = (defaultLang = 'zh') => {
  if (isBrowser) {
    // const lang = Cookies.get('lang') || window.navigator.language
    // if (lang in LANGUAGES) {
    //   return LANGUAGES[lang].value
    // }
  }

  return defaultLang
}

export const getHomeSlug = (pageContext = {}) => {
  const { locale, isDefaultLocale } = pageContext

  return isDefaultLocale ? '/' : `/${locale}/`
}

export const scrollTo = (offset = 0, duration = 1000) => {
  let start = window.performance.now()
  const step = timestamp => {
    const process = (timestamp - start) / duration

    window.scrollTo(0, offset * process)

    if (process < 1) {
      window.requestAnimationFrame(step)
    }
  }

  window.requestAnimationFrame(step)
}
