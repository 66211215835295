import React, { useEffect } from 'react'
import { LocaleProvider, Layout } from 'antd'
import { object, node } from 'prop-types'
import { addLocaleData, IntlProvider } from 'react-intl'

import Header from 'components/Header'
import Footer from 'components/Footer'
import { scrollTo } from 'utils'
import { getAppLocale } from 'utils/locale'

import styles from './index.module.less'

const AppLayout = ({ pageContext, location, children }) => {
  const { locale, slug } = pageContext
  // No Layout rendering if loading from service worker
  if (slug === '/offline-plugin-app-shell-fallback/') return null

  const appLocale = getAppLocale(locale)

  addLocaleData(appLocale.data)

  useEffect(() => {
    const { hash } = location

    if (typeof window === 'undefined') return

    if (hash) {
      let elem = window.document.querySelector(hash)

      if (elem) {
        let distance = 0

        // Loop up the offset from the viewport top
        if (elem.offsetParent) {
          while (elem) {
            distance += elem.offsetTop
            elem = elem.offsetParent
          }
        }

        if (distance > 0) {
          scrollTo(distance - 150, 500)
        }
      }
    } else {
      window.document.body.scrollTop = 0
      window.scrollTo(0, 0)
    }
  }, [location])

  return (
    <LocaleProvider locale={appLocale.antd}>
      <IntlProvider locale={locale} messages={appLocale.messages}>
        <Layout className={`${styles.mainLayout} page__${locale}`}>
          <Header pageContext={pageContext} />
          <Layout.Content className={styles.mainContent}>
            {children}
          </Layout.Content>
          <Footer locale={locale} />
        </Layout>
      </IntlProvider>
    </LocaleProvider>
  )
}

AppLayout.propTypes = {
  pageContext: object.isRequired,
  children: node.isRequired
}

export default AppLayout
