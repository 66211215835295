import moment from 'moment'
import 'moment/locale/zh-cn'

import logoZh from 'assets/images/logo.png'
import logoEn from 'assets/images/logo_en.png'
import logoZhWhite from 'assets/images/logo_white.png'
import logoEnWhite from 'assets/images/logo_en_white.png'
import zh from 'locales/zh'
import en from 'locales/en'

const locales = {
  zh,
  en
}

export const getAppLocale = (locale = 'en') => {
  // eslint-disable-line
  switch (locale) {
    case 'zh':
      moment.locale('zh-cn')
      break
    case 'en':
    default:
      moment.locale('en')
      break
  }

  return locales[locale]
}

const LOGOS = {
  black: {
    zh: logoZh,
    en: logoEn
  },
  white: {
    zh: logoZhWhite,
    en: logoEnWhite
  }
}

export const getLocalLogo = (locale = 'en', isWhite = false) =>
  LOGOS[isWhite ? 'white' : 'black'][locale]
