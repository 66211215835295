import antd from 'antd/lib/locale-provider/en_US'
import localeData from 'react-intl/locale-data/en'
import messages from './json/en.yml'
import { flattenMessages } from './utils'

export default {
  messages: flattenMessages(messages),
  antd,
  data: localeData
}
