import React, { useState } from 'react'
import { object, array, string, bool } from 'prop-types'
import { Link } from 'gatsby'
import { Menu, Button, Icon } from 'antd'
import { FormattedMessage } from 'react-intl'

import { locales } from '../../../config/i18n'
import styles from './index.module.less'

const getBaseSlug = (slug, locale) => {
  if (slug.includes('/news')) {
    return '/news'
  }

  const pattern = `^\/${locale}\/`
  const regex = new RegExp(pattern, 'g')

  return slug.replace(regex, '/')
}

const getMenus = (menus = [], pathPrefix) => {
  if (menus.length === 0) return null

  return menus.map(menu => {
    const { path, children } = menu
    const localedPath = `${pathPrefix}${path}`
    const title = <FormattedMessage id={`menus.${path}`} />

    if (children && children.length > 0) {
      const pathArr = path.split('/').filter(item => !!item)

      if (pathArr.length > 1) {
        return (
          <Menu.ItemGroup key={localedPath} title={title}>
            {getMenus(children, pathPrefix)}
          </Menu.ItemGroup>
        )
      }

      const hasThridLevel = children.some(
        subMenu => subMenu.children && subMenu.children.length > 0
      )

      return (
        <Menu.SubMenu
          key={localedPath}
          title={title}
          popupClassName={`${styles.headerSub} ${
            hasThridLevel ? styles.horizontalSub : ''
          }`}
        >
          {getMenus(children, pathPrefix)}
        </Menu.SubMenu>
      )
    }

    return (
      <Menu.Item key={localedPath}>
        <Link to={localedPath}>{title}</Link>
      </Menu.Item>
    )
  })
}

const TopNavMenu = ({
  mobile,
  pageContext,
  mode,
  theme,
  topMenu,
  selectedNavKeys,
  selectedLangKeys
}) => {
  const { slug = '', locale, isDefaultLocale } = pageContext
  const baseSlug = getBaseSlug(slug, locale)
  const pathPrefix = isDefaultLocale ? '' : `/${locale}`
  const rootMenuKeys = topMenu.map(m => `${pathPrefix}${m.path}`)
  const defaultNavOpenKeys = mobile
    ? rootMenuKeys.filter(k => slug.indexOf(k) > -1)
    : []
  const [openKeys, setOpenKeys] = useState(defaultNavOpenKeys)
  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1)

    if (rootMenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }

  return (
    <div className={styles.right}>
      <Menu
        mode={mode}
        theme={theme}
        openKeys={openKeys}
        selectedKeys={selectedNavKeys}
        onOpenChange={onOpenChange}
      >
        {getMenus(topMenu, pathPrefix)}
      </Menu>
      {locales.length > 1 && (
        <Menu
          mode={mode}
          theme={theme}
          selectedKeys={selectedLangKeys}
          className={styles.langMenu}
        >
          <Menu.SubMenu
            title={
              <span>
                <Icon type="global" />
                <FormattedMessage id="header.language" />
              </span>
            }
            className={styles.langMenuSub}
          >
            <Menu.Item key="en">
              <Link to={`/en/${baseSlug}`}>English</Link>
            </Menu.Item>
            <Menu.Item key="zh">
              <Link to={baseSlug}>中文</Link>
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
      )}
      {/* {isDefaultLocale && (
        <Menu
          selectedKeys={[]}
          mode={mode}
          theme={theme}
          className={styles.user}
        >
          <Menu.Item key="login" className={styles.login}>
            <a
              href="http://aiopen.kankanai.com.cn/#/login"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button type="primary">登录</Button>
            </a>
          </Menu.Item>
          <Menu.Item key="signup" className={styles.signup}>
            <Link to="/about-us#contact">申请</Link>
          </Menu.Item>
        </Menu>
      )} */}
    </div>
  )
}

TopNavMenu.propTypes = {
  mobile: bool,
  theme: string,
  mode: string.isRequired,
  topMenu: array.isRequired,
  selectedNavKeys: array.isRequired,
  selectedLangKeys: array.isRequired,
  pageContext: object.isRequired
}

TopNavMenu.defaultProps = {
  mobile: false,
  theme: ''
}

export default TopNavMenu
