import React, { useState, useEffect } from 'react'
import { object } from 'prop-types'
import { Link } from 'gatsby'
import { Row, Col, Layout, Popover, Icon } from 'antd'
import throttle from 'lodash/throttle'

import { BREAKPOINT_DESKTOP } from 'constants/site'
import { getHomeSlug } from 'utils'
import { getLocalLogo } from 'utils/locale'
import menus from '../../../content/menus.yml'
import TopNavMenu from './TopNavMenu'
import styles from './index.module.less'

const getActiveMenuKeyFromSlug = slug => {
  if (!slug) return []

  // use '/news' for post pages
  return [slug.replace(/\/news\/.*/, '/news/')]
}

const Header = ({ pageContext }) => {
  const { slug, locale } = pageContext
  const homeSlug = getHomeSlug(pageContext)
  const isHome = slug === homeSlug
  const selectedNavKeys = getActiveMenuKeyFromSlug(slug)
  const selectedLangKeys = [locale]
  const [isTransparent, setIsTransparent] = useState(isHome)
  const handleScroll = throttle(e => {
    setIsTransparent(window.scrollY === 0)
  })
  const handleMouseEnter = () => {
    if (isHome) {
      setIsTransparent(false)
    }
  }
  const handleMouseLeave = () => {
    if (isHome && window.scrollY === 0) {
      setIsTransparent(true)
    }
  }

  useEffect(() => {
    if (isHome && window.innerWidth >= BREAKPOINT_DESKTOP) {
      setIsTransparent(true)
      window.addEventListener('scroll', handleScroll)
    } else {
      setIsTransparent(false)
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [slug])

  return (
    <Layout.Header
      key={slug}
      className={`${styles.header} ${
        isTransparent ? styles.headerTransparent : ''
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="container">
        <Row gutter={16}>
          <Col className={styles.logo} xs={24} lg={4}>
            <Link to={homeSlug}>
              <img src={getLocalLogo(locale, isTransparent)} alt="" />
            </Link>
            <Popover
              overlayClassName={styles.popover}
              placement="bottomRight"
              autoAdjustOverflow={false}
              trigger="click"
              content={
                <TopNavMenu
                  mobile
                  pageContext={pageContext}
                  mode="inline"
                  topMenu={menus}
                  selectedNavKeys={selectedNavKeys}
                  selectedLangKeys={selectedLangKeys}
                />
              }
            >
              <Icon className={styles.navIcon} type="menu" />
            </Popover>
          </Col>
          <Col xs={0} lg={20}>
            <TopNavMenu
              pageContext={pageContext}
              mode="horizontal"
              topMenu={menus}
              selectedNavKeys={selectedNavKeys}
              selectedLangKeys={selectedLangKeys}
            />
          </Col>
        </Row>
      </div>
    </Layout.Header>
  )
}

Header.propTypes = {
  pageContext: object.isRequired
}

export default Header
